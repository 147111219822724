<template>
  <section class="settings">
    <ComingSoon />
  </section>
</template>
<script>
import ComingSoon from "../components/ComingSoon";

export default {
  components: { ComingSoon },
};
</script>
